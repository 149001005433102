import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import RobotImg from "../img/robotImg.png"
import ElsaMCoverflowEffect from "../components/robot/ElsaMCoverflowEffect"

import RobotImg1 from "../img/robotMain_01.png"
import RobotImg2 from "../img/robotMain_02.png"
import RobotImg3 from "../img/robotMain_03.png"
import RobotImg4 from "../img/robotMain_04.png"
import robotImage1 from "../img/robot_image1.png"
import circle1 from "../img/circle_1.png"

import classNames from "classnames"

function useScroll(position) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handler = () => {
      setScrolled(window.scrollY > position)
    }
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [position])

  return scrolled
}

function Robot() {
  const scrolled = useScroll(3700)
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div
        className={classNames(
          "elsaWrap backColor robotWrap",
          scrolled && "backColorAnimatedRobot"
        )}
      >
        <div className="sectionWrap elsaOverview robotOverview">
          <p className="elsaMainImg"></p>
          <div className="textWrap">
            <h2>
              스마트 모빌리티 인프라 서비스 플랫폼
              <span>서빙 로봇, 무인배송, 공장/창고에서의 물류 이송</span>
            </h2>
            <div className="bodyText">
              레스토랑의 접시 배달에서 무인 배송, 물류 이송에 이르기까지 로봇은
              사람 대신에 작은일 처리 또는 무거운 상품 이동에 도움이 되어 인간의
              생활 질을 향상시키고 수준 높은 서비스 경험을 만들 수 있습니다.
              <br />
              <br />
              승강기, 에스컬레이터 등 건물 내 이동시설과 로봇간에 안정적이고
              보안이 완비된 연동은 더 높은 수준의 로봇 서비스를 제공하는데 필수
              요소입니다. 최근 엠투엠테크의 통합관제 시스템과 물류/서빙/배송
              로봇과의 성공적인 연동으로 엠투엠테크는 로봇 산업에서 인간 생활에
              효율적인 많은 프로젝트들에 참여하고 있습니다.
            </div>
          </div>
        </div>
        <div className="sectionWrap elsaDetail robotDetail">
          <div className="textWrap">
            <h2>
              디지털 신기술 발전과 물동량 증가, 노동 부족을 해결하는 스마트
              배송, 물류 로봇
            </h2>
            <p className="bodyText">
              무인택배 서비스에서는 스마트홈 관제시스템, 승강기 관제, 아파트
              단지 택배 시스템 등 스마트 무빙 오브젝트와의 연동이 필수입니다.
              디지털 트랜스포메이션 시대의 핵심 기기인 로봇 가운데 물류 로봇은
              인구 고령화와 생산성 향상 등 경제·사회 문제 해결 잠재력이 높은
              유망 분야로 떠오르고 있습니다.
            </p>
          </div>
          <div className="robotMainImg">
            <img src={RobotImg} alt="" />
          </div>
          <div className="textWrap">
            <h2>
              로봇의 빌딩내 안전하고 빠른 이동을 보장하는 승강기와의 양방향 통신
            </h2>
            <p className="bodyText">
              엠투엠테크는 비대면 배달로 감염병 확산 방지, 범죄 예방 등 소비자의
              편익이 예상되는 로봇 배송 서비스가 시장에 잘 정착하는 데 기여할 수
              있는 로봇의 층 간 이동이 가능한 시스템을 개발했습니다. 로봇 수직
              이동의 안전 확보를 위해서는 승강기와의 양방향 통신이 필수적인데,
              일방적인 승강기 제어는 승강기의 안전 상태를 확인하기 어렵습니다.
              이때, 글로벌 대·중소기업 승강기 제조사가 난립하여 있고, 한 제조사
              내에서도 수많은 모델·기종이 있어 서비스 확대에 어려움이 있습니다.
              <br />
              <br />
              엠투엠테크는 국제표준 기술을 제안·승인받는 성과를 얻어 신규
              승강기의 표준화와 함께 기존 승강기와의 연동 문제를 해결하고,
              스마트 모빌리티 인프라 서비스로 다양한 제조사 모델·기종의 승강기와
              안전하게 연동하고 로봇과 승강기 간의 원활한 소통을 하도록 해 수직
              이동이 가능한 로봇 배송 서비스 시장 확대에 전환점에 기여할
              예정입니다. 최근 국내외 글로벌 자율주행로봇 전문 기업들과 공동으로
              라스트마일 구간에서의 배송 서비스를 실증하고 있습니다.
            </p>
          </div>

          <div className="elsaBenefit robotBenefit colorYellow">
            <ul>
              <li>
                <p className="kind">주거환경 통합형 제어</p>
              </li>
              <li>
                <p className="kind">높은수준의 보안</p>
              </li>
              <li>
                <p className="kind">다양한 제조사 모델·기종과의 연동</p>
              </li>
              <li>
                <p className="kind">특허 출원</p>
                <p className="kindDetail">
                  ‘승강기와 외부데이터 연동하는 방법 및 장치’, ‘네트워크 연동
                  승강기 관리 환경에서 보안 방법 및 장치’ 2건
                </p>
              </li>
            </ul>
          </div>
          <div className="robotProduct">
            <ul>
              <li>
                <div className="imgBox">
                  <img src={RobotImg1} alt="" />
                </div>
                <div className="textWrap">
                  <h2>
                    ELSA IoT Gateway<span className="tmText">TM</span>
                  </h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    승강기의 상태를 연동 수집하고 다양한 제조사 기종의
                    승강기와의 직접 연동을 통해 시스템 상호 운용성을 보장합니다
                  </p>
                </div>
              </li>
              <li className="elsaServices1">
                <div className="imgBox">
                  <img src={RobotImg2} alt="" />
                </div>
                <div className="textWrap">
                  <h2>ELSA.Edge</h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    사이트 단위에서 독립적으로 로봇과 승강기를 연동하고
                    클라우드, Robot CMS 와 연결이 가능합니다.
                  </p>
                </div>
              </li>
              <li className="elsaServices2">
                <div className="imgBox">
                  <img src={RobotImg3} alt="" />
                </div>
                <div className="textWrap">
                  <h2>
                    ELSA IoT Gateway Lite<span className="tmText">TM</span>
                  </h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    자동문 &amp; 스피드 게이트 와 연동하여 배송 로봇의 출입을
                    통제/관리합니다.
                  </p>
                </div>
              </li>
              <li className="elsaServices2">
                <div className="imgBox">
                  <img src={RobotImg4} alt="" />
                </div>
                <div className="textWrap">
                  <h2>ELSA Cloud</h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    클라우드 기반의 로봇-승강기 배송서비스를 위하여 플랫폼간
                    연동 서비스를 지원합니다.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="platformDescription_wrap">
            <div className="platformDescription_flexItem">
              <img
                alt="Robot"
                src={robotImage1}
                className="platformDescription_mainImage"
              />
            </div>
            <div
              className="platformDescription_flexItem"
              style={{ paddingBottom: 45 }}
            >
              <p>
                <h3 className="platformDescription_title">
                  상호운용성이 보장되는 표준화된 로봇-빌딩 인프라 연동 API 제공
                </h3>
              </p>

              <p>
                배송 목적지까지 정확성 및 안정성을 보장으로 고객 신뢰성 확보 ·
                목적지 배송까지 인프라 환경에 제조사/기종 종류 관계없이 모두
                지원하는 API 제공에 목적이 있음 · 다수의 배달‧서빙로봇
                서비스업체에 개별 인프라 환경에 맞게 연동 개발을 지양하고,
                표준화된 API 제공으로 연동 비용 절감에 목적이 있음
              </p>
              {/* <div className="platformDescription_buttonWrap">
                <a
                  fill={true}
                  color="primary"
                  size="s"
                  href="https://robot.elsa.cloud"
                  target="_blank"
                >
                  플랫폼 알아보기
                </a>
                <a
                  href="https://robot.elsa.cloud/product/price"
                  target="_blank"
                  fill={true}
                  size="s"
                  color="primary"
                >
                  구독 가격
                </a>
                <a
                  fill={true}
                  size="s"
                  color="primary"
                  href="https://docs.robot.elsa.cloud/"
                  target="_blank"
                >
                  개발자문서 보기
                </a>
              </div> */}
            </div>
            <div className="platformDescription_circle1">
              <img src={circle1} alt="color" />{" "}
            </div>
          </div>
          <div className="sectionWrap" style={{ paddingBottom: 0 }}>
            <div className="textWrap">
              <h2>사용자에 최적화된 Open API 구독 플랫폼</h2>
              <p className="bodyText">
                데스크탑, 패드, 모바일 등 다양한 디바이스에서 일목요연한
                사용량분석과 함께 현장, 로봇, 인프라 관리 화면을 제공합니다.
                손쉽게 현장과 승강기, 로봇등의 항목을 추가 / 관리할수 있고
                사용한 만큼 과금하여 자동 결제되는 구독 서비스를 관리할 수
                있습니다.
              </p>
            </div>
            <div className="elsaCloudGallery" style={{ paddingBottom: 10 }}>
              <ElsaMCoverflowEffect />
            </div>
          </div>
          <div
            className="elsaBenefit robotBenefit"
            style={{ padding: "110px 0 40px" }}
          >
            <div className="robotBenefitTitle">
              <h2>엘사통합관계시스템과 로봇사업간의 연동 분야</h2>
            </div>
            <ul>
              <li>
                <p className="kind">자율주행 물류로봇</p>
                <p className="kindDetail">
                  물류로봇의 주력 용도로 AGV(무인운반로), 무인지게차, 이동형
                  매니퓰레 이터(로봇팔), 컨베이어 시스템 등을
                  WMS(창고관리시스템) 등과 연동하여 활용합니다. 최근 처리 물품이
                  급증하고 있는 전자상거래 업체가 물류센터 자동화를 목적으로
                  물류로봇을 활용하고 있습니다.
                </p>
              </li>
              <li>
                <p className="kind">스마트 승강기 + 로봇</p>
                <p className="kindDetail">
                  상업빌딩, 사무실, 병원 등 실내에서 문서 및 물건 이송을 위해
                  로봇과 스마트 승강기간의 연동 도입이 확대되는 추세입니다.
                  승강기와 연동한 중소형 물품 이송 중심의 옥내용
                  자율승강로봇으로서 현재 유통물류업을 비롯해 제조, 호텔, 의료,
                  건설 등 다양한 업종에서 적용되고 있습니다.
                </p>
              </li>
              <li>
                <p className="kind">무인택배 수직배송서비스로 확대</p>
                <p className="kindDetail">
                  택배업의 최종 배송 구간의 효율성 확보, 농업 등 다양한 산업에의
                  뛰어난 적용 가능성 등을 배경으로 높은 성장잠재력이 기대됩니다.
                  로봇과 승강기간 연동을 통한 수평수직 배송서비스는 기존
                  특례에서 지난 3월 2일 ‘승강기 안전관리법’ 행정안전부 고시가
                  제정됨으로써 활기를 뛸 전망이고 로봇의 승강기 탑승을 활용하는
                  사업자는 비대면 배달로 감염병 확산 방지, 범죄예방 등 소비자의
                  편익이 예상됩니다.
                </p>
              </li>
            </ul>
          </div>

          <div className="elsaAchieve robotAchieve">
            <div className="textWrap">
              <h2>
                ICT 융합기술과 스마트홈 서비스 발전으로 총 물류비의 50% 이상을
                차지하는 “라스트 마일(Last Mile) 구간의 비대면 무인 배송서비스
                시장은 성장성이 무한합니다.
              </h2>
              <p className="bodyText">
                특히 COVID-19 대유행으로 1인당 연간 60개 이상의 택배가 사용되고
                배달원 1인당 하루 177개 발송되는 등 전자상거래 시장에 활력이
                불고있습니다. 이에 물류(유통)업체들이 배달 시간을 단축하고
                안전한 비대면 배송 서비스를 개발하기 위해 스마트 모바일
                기기(배달로봇 등) 개발을 추진하고 있습니다. ㈜엠투엠테크는
                라스트마일 서비스에 참여하거나 소비자인 국민의 삶의 질을 높이기
                위해 스마트홈, 물류(유통) 서비스, 상용로봇 등 산업 간 융합
                생태계를 활성화합니다.
              </p>
            </div>
            <div className="elsaBenefit robotFuture">
              <ul>
                <li>
                  <p className="kindDetail">
                    라스트 마일 구간에 참여하는 플랫폼 노동자의 근무 여건 개선과
                    안전한 비대면 배송 서비스로 고가의 라스트 마일 구간의 물류
                    문제를 해결합니다.
                  </p>
                </li>
                <li>
                  <p className="kindDetail">
                    아파트 단지 내 스마트홈 서비스와 외부 생활서비스(구매/공유
                    배송 등)를 연계해 다양한 융합 신산업 생태계를 확대할 수
                    있습니다.
                  </p>
                </li>
                <li>
                  <p className="kindDetail">
                    안전기준 및 인증체계를 구축하여 서비스 신뢰성을 확보하고
                    안전사고를 사전에 예방하고 있습니다.
                  </p>
                </li>{" "}
                <li>
                  <p className="kindDetail">
                    다양한 정부 부처(국토교통부, 과학기술부 등) 간 규제를
                    성공적으로 혁신하고 정부 협업 활동을 활성화 및 활용할 수
                    있습니다.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Robot
